<template>
	
	<div class="comments">
		<div v-if="loading" class="d-flex align-items-center justify-content-center">
			<span class="spinner-border spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</span>
		</div>

		<div v-else-if="comments">

			<div class="btn-group mb-2 w-100 border-bottom">
				<button
				v-on:click="[tab = 'todo']" 
				class="btn border-0 border-bottom border-2 rounded-0"
				:class="[tab == 'todo' ? 'border-primary' : 'border-light']"
				>
					To do
				</button>
				<button
				v-on:click="[tab = 'done']" 
				class="btn border-0 border-bottom border-2 rounded-0"
				:class="[tab == 'done' ? 'border-primary' : 'border-light']"
				>
					Done
				</button>
			</div>

			<form v-if="tab == 'todo' && is_sitemap" @submit.prevent="add" class="mb-2">
				<textarea type="text" name="comment" class="form-control" placeholder="Add comment..." v-model="comment"></textarea>
				<div class="mt-2">
					<btn_submit 
						v-if="comment"
						label="Add comment" 
						icon="add"  
						:icon_after="true" 
						:loading="add_loading" 
					/>
				</div>
			</form>

			<div v-if="filtered_comments">
				<div v-for="comment in filtered_comments" :key="'comment-' + comment.id" :action="preview + comment.page + '#' + comment.id">
						
					<div class="card mb-2">
						<div class="card-body p-0 position-relative">

							<div class="d-flex align-items-center justify-content-between mb-2 pt-2 pb-0">
								<div class="px-2">
									<p class="fw-bold mb-0">{{ comment.user }}</p>
									<p class="form-text mt-0 mb-0">{{ comment.clean_date }}</p>
								</div>
								<div class="d-flex align-items-center justify-content-end">

									<div v-if="editing != comment.id" class="dropdown">
										<button 
										class="btn btn-link px-2"  
										type="button" 
										:id="'comment-' + comment.id + '-dropdown'" 
										data-bs-toggle="dropdown" 
										aria-expanded="false"
										>
											<span class="material-icons-outlined">more_vert</span>
										</button>
										<ul class="dropdown-menu text-start py-0" :aria-labelledby="'comment-' + comment.id + '-dropdown'">
											
											<li v-if="!is_sitemap && comment.page">
												<form  method="GET" target="iframe-preview" :action="preview + comment.page + '#' + comment.id">
													<input type="hidden" name="type" :value="type">
													<button v-on:click="$emit('update:previewSize', comment.device)" class="dropdown-item d-flex align-items-center py-2">
														<span class="material-icons-outlined me-2">visibility</span>
														View
													</button>
												</form>
											</li>

											<li v-if="!comment.is_done">
												<button type="button" v-on:click="[comment.is_done = true, update( comment )]" class="dropdown-item d-flex align-items-center py-2">
													<span class="material-icons-outlined me-2">check</span>
													Complete
												</button>
											</li>
											
											<li v-else>
												<button type="button" v-on:click="[comment.is_done = false, update( comment )]" class="dropdown-item d-flex align-items-center py-2">
													<span class="material-icons-outlined me-2">close</span>
													Incomplete
												</button>
											</li>

											<li v-if="auth_user.email == comment.user">
												<a href="#" v-on:click="[comment.new_comment = comment.comment, editing = comment.id]" class="dropdown-item d-flex align-items-center py-2">
													<span class="material-icons-outlined me-2">edit</span>
													Edit
												</a>
											</li>

											<li v-if="auth_user.email == comment.user">
												<button type="button" v-on:click="[delete_comment( comment )]" class="dropdown-item d-flex align-items-center py-2">
													<span class="material-icons-outlined me-2">delete_forever</span>
													Delete
												</button>
											</li>

										</ul>
									</div>
								</div>
							</div>

							<div v-if="editing == comment.id" class="p-2 pt-0">
								<form @submit.prevent="update( comment )">
									<textarea type="text" name="comment" class="form-control" placeholder="Add comment..." v-model="comment.new_comment"></textarea>
									<div class="mt-2">
										<btn_submit 
											v-if="comment.new_comment"
											label="Update comment" 
											icon="check"  
											:icon_after="true" 
											:loading="update_loading" 
										/>
									</div>
								</form>
							</div>
							<div v-else class="p-2 pt-0">

								<p class="mb-0 small">{{ comment.comment }}</p>

								<button v-on:click="toggle_more( comment.id )" class="btn btn-link px-0 small position-relative" style="z-index: 2; font-size: 12px;">Details</button>
							</div>
						</div>

						<div class="list-group list-group-flush mb-0 small" :class="more == comment.id && !editing ? 'd-block' : 'd-none'">
							<div class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">User</span>
								<span class="w-50 text-end">{{ comment.user }}</span>
							</div>
							<div class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Date</span>
								<span class="w-50 text-end">{{ comment.date }}</span>
							</div>
							<div v-if="comment.device" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Device</span>
								<span class="w-50 text-end">{{ comment.device }}</span>
							</div>
							<div v-if="comment.device_type" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Device Type</span>
								<span class="w-50 text-end">{{ comment.device_type }}</span>
							</div>
							<div v-if="comment.browser" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Browser</span>
								<span class="w-50 text-end">{{ comment.browser }}</span>
							</div>
							<div v-if="comment.width > 0" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Dimensions</span>
								<span class="w-50 text-end">{{ comment.width }}px x {{ comment.height }}px</span>
							</div>
							<div v-if="comment.page" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Page</span>
								<span class="w-50 text-end" style="line-break: anywhere;">{{ comment.page }}</span>
							</div>
							<div v-if="comment.is_done_user" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Completed by</span>
								<span class="w-50 text-end">{{ comment.is_done_user }}</span>
							</div>
							<div v-if="comment.is_done_date" class="list-group-item p-2 d-flex justify-content-between small">
								<span class="w-50">Completed date</span>
								<span class="w-50 text-end">{{ comment.is_done_date }}</span>
							</div>
						</div>
						
					</div>

				</div>
			</div>

			<div v-else>
				<div class="card">
					<div class="card-body p-2 text-center small">
						There are no comments
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<div class="card">
				<div class="card-body p-2 text-center small">
					There are no comments
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import { mapGetters } from 'vuex';
import btn_submit from '@/components/btn_submit'
import site_service from '@/services/site_service'

export default {
	name: 'components.comments',
	props: {
		workspace: [Array, Object],
		site: [Array, Object],
		is_build: Boolean,
		is_sitemap: Boolean
	},
	components: {
		btn_submit
	},
	emits: [
		'update:previewSize',
	],
	data() {
		return {
			loading: false,
			add_loading: false,
			update_loading: false,
			delete_loading: false,
			comment_loading: false,
			comments: null,
			comment: '',
			more: null,
			tab: 'todo',
			editing: null
		}
	},
	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain
			}

			return url
		},

		type()
		{
			return ( this.is_build ? 'build' : ( this.is_sitemap ? 'sitemap' : 'design' ) )
		},

		filtered_comments()
		{
			var comments = {}
			var has_comments = false
			
			if ( this.comments[this.type] ) {
				if ( this.tab == 'todo' ) {
					Object.keys( this.comments[this.type] ).forEach(( key ) => {
						var comment = this.comments[this.type][key];
						if ( !comment['is_done'] ) {
							comments[key] = comment
							has_comments = true
						}
					});
				} else {
					Object.keys( this.comments[this.type] ).forEach(( key ) => {
						var comment = this.comments[this.type][key];
						if ( comment['is_done'] ) {
							comments[key] = comment
							has_comments = true
						}
					});
				}
			}
			return ( has_comments ? comments : null )	
		}
	},
	mounted()
	{
		this.get_comments();

		var vm = this;
		var $ = window.$
		$(window).on('message', function(event) {
			var data = event.originalEvent.data;
			if ( data.origin === 'areoi-site-comment' ) {
				vm.get_comments();				
			}
		});	
	},
	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_comments()
		{
			this.loading = true 

			site_service.comments( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.comments = JSON.parse( JSON.stringify( response.data ) )

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		async add()
		{
			if ( !this.comment ) {
				return
			}
			this.add_loading = true 

			site_service.add_comment( this.$route.params.workspace_id, this.$route.params.site_id, {
				'type': this.type,
				'comment' : this.comment 
			}).then(( response ) => {

				this.comments = JSON.parse( JSON.stringify( response.data ) )

				this.comment = ''

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.add_loading = false

			})
		},

		async update( comment )
		{
			this.update_loading = true 

			comment.type = this.type

			site_service.update_comment( this.$route.params.workspace_id, this.$route.params.site_id, comment ).then(( response ) => {

				this.comments = JSON.parse( JSON.stringify( response.data ) )

				this.editing = null

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.update_loading = false

			})
		},

		async delete_comment( comment )
		{
			this.delete_loading = true 

			comment.type = this.type

			site_service.delete_comment( this.$route.params.workspace_id, this.$route.params.site_id, comment ).then(( response ) => {

				this.comments = JSON.parse( JSON.stringify( response.data ) )

				this.editing = null

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.delete_loading = false

			})
		},

		toggle_more( id )
		{
			if ( this.more == id ) {
				this.more = null
			} else {
				this.more = id
			}
		}
	}
}
</script>

<style>

</style>
